<template>
  <div>
    <div class="auth-page__header">
      <div class="auth-page__title">{{ $t("newPassword") }}</div>
    </div>
    <div class="auth-confirm__subtitle">{{ $t("enterNewPassReg") }}</div>
    <form
      id="sign-up-form"
      method="post"
      novalidate="true"
      class="auth-page__form"
      @submit="onSubmit"
    >
      <easy-input
        v-model="newPassword"
        :label="$t('newPassword')"
        :errorText="$t('newPasswordError')"
        :isError="isSubmitted && !newPassword"
        isPassword
      />
      <easy-input
        v-model="confirmNewPassword"
        :label="$t('confirmNewPassword')"
        :errorText="$t('confirmPasswordError')"
        :isError="isSubmitted && confirmNewPassword != newPassword"
        isPassword
      />
      <button
        :class="{ disabled: isNotValid, isLoading: isLoading }"
        class="btnWideRound"
        @click="onSubmit"
      >
        {{ $t("btnNext") }}
      </button>
    </form>
  </div>
</template>

<script>
import EasyInput from "@/components/common/EasyInput";
import eventsRegister from "@/services/events-register";
import { mapActions } from "vuex";

export default {
  name: "ResetPasswordNew",
  components: {
    EasyInput,
  },
  data() {
    return {
      newPassword: "",
      confirmNewPassword: "",

      isSubmitted: false,
      isLoading: false,
    };
  },
  computed: {
    isNotValid() {
      return (
        !this.newPassword ||
        !this.confirmNewPassword ||
        this.newPassword !== this.confirmNewPassword
      );
    },
  },
  methods: {
    ...mapActions("user", ["resetNewPass"]),
    async onSubmit(e) {
      e.preventDefault();
      if (this.isLoading) return false;
      this.isSubmitted = true;

      if (!this.isNotValid) {
        this.isLoading = true;
        try {
          await this.resetNewPass(this.newPassword);
          this.$router.push({ name: "Root" });
          this.isLoading = false;
        } catch (error) {
          const message = error.Message
            ? this.$t("phoneRegErrorExist")
            : this.$t("serverError");
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message,
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style></style>
